import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import LazyLoad from "react-lazyload";
// import AniLink from "gatsby-plugin-transition-link/AniLink";

// Components
import { ShortVideo } from "../videos/short-video";
import { VimeoThumbnail } from "../images/vimeo-thumbnail";
import { Caption } from "../text/caption";

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-flow: dense;

  width: 100%;
`;

const VideoContainer = styled.div`
  position: relative;

  width: 100%;
  height: 100%;

  grid-column-end: ${props => props.column};
  grid-row-end: ${props => props.rowColumn};

  overflow: hidden;

  border: 1px solid #1d1d1b;

  pointer-events: all;

  & a {
    display: block;

    width: 100%;
    height: 100%;

    position: relative;
  }
`;

const VideoWrapper = styled.div`
  position: relative;

  width: 100%;
  height: 100%;

  overflow: hidden;

  &:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: ${props => props.ratio}%;
  }

  & > .content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  & img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
`;

export const MobileFilteredProductionVideos = ({
  allVideos,
  videoLocation,
  location,
  activeFilter,
}) => {
  const filteredVideos = allVideos
    .filter(video => video.node !== null)
    .filter(video => video.node.tags.includes(activeFilter));

  const videos = filteredVideos.map((content, index) => {
    return (
      <VideoContainer
        key={`director_video_${index}_${content.node.id}`}
        column={index % 7 ? `span 2` : `span 2`}
        rowColumn={index % 7 ? `span 1` : `span 2`}
      className={index % 7 ? `landscape` : `square`}
      >
        <Link
          to={`/${content.node.uid}`}
          state={{
            location: location,
            videoLocation: videoLocation,
            filteredVideos: filteredVideos,
          }}
        >
          <VideoWrapper
            ratio={index % 7 ? `56.25` : `100`}
          >
            <div className="content">
              <VimeoThumbnail
                width={1920}
                height={1080}
                prismicThumbnail={content.node.data.thumbnail.fluid}
                embed_url={content.node.data.vimeo_video.embed_url}
              />
            </div>
          </VideoWrapper>
          <Caption>
            {content.node.data.brand.text !== "" ? (
              <h2>{content.node.data.brand.text}</h2>
            ) : (
              <h2>{content.node.data.title.text}</h2>
            )}
            {content.node.data.director.uid !== null && (
              <p>{content.node.data.director.document.data.title.text}</p>
            )}
          </Caption>
        </Link>
      </VideoContainer>
    );
  });

  return <Container>{videos}</Container>;
};
