import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import LazyLoad from "react-lazyload";

// Components
import { ShortVideo } from "../videos/short-video";
import { VimeoThumbnail } from "../images/vimeo-thumbnail";
import { Caption } from "../text/caption";

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-flow: dense;

  width: 100%;
`;

const VideoContainer = styled.div`
  position: relative;

  width: 100%;
  height: 100%;

  grid-column-end: ${props => props.column};
  grid-row-end: ${props => props.rowColumn};

  overflow: hidden;

  border: 1px solid #1d1d1b;

  pointer-events: ${props => props.pointerEvents};

  & a {
    display: block;

    width: 100%;
    height: 100%;

    position: relative;
  }
`;

const VideoWrapper = styled.div`
  position: relative;

  width: 100%;
  height: 100%;

  overflow: hidden;

  &:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: ${props => props.ratio}%;
  }

  & > .content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  & img {
    transition: 250ms opacity ease;

    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  &:hover img {
    opacity: ${props => (props.fadeOutImage ? 0 : 1)};
  }
`;

export const DesktopFilteredProductionVideos = ({
  allVideos,
  videoLocation,
  location,
  activeFilter,
}) => {
  const [currentVideoPlayerId, setCurrentVideoPlayerId] = useState("");
  const [shouldImageFadeOut, setImageFadeOut] = useState(false);
  const [allowPointerEvents, setAllowPointerEvents] = useState("none");
  const [onReady, setOnReady] = useState(false);

  // Order should be:
  // 0: Square
  // 1: Landscape
  // 2: Landscape
  // 3: Landscape
  // 4: Landscape
  // 5: Landscape
  // 6: Landscape
  // 7: Square

  useEffect(() => {
    setAllowPointerEvents(`all`);
  }, [onReady, setAllowPointerEvents]);

  const filteredVideos = allVideos
    .filter(video => video.node !== null)
    .filter(video => video.node.tags.includes(activeFilter));

  const videos = filteredVideos.map((content, index) => (
    <VideoContainer
      key={`director_video_${index}_${content.node.id}`}
      column={index % 7 ? `span 1` : `span 1`}
      rowColumn={index % 7 ? `span 1` : `span 2`}
      className={index % 7 ? `landscape` : `square`}
      onMouseOver={() => setCurrentVideoPlayerId(content.node.id)}
      onMouseLeave={() => setCurrentVideoPlayerId("")}
      pointerEvents={allowPointerEvents}
    >
      <Link
        to={`/${content.node.uid}`}
        state={{
          location: location,
          videoLocation: videoLocation,
          filteredVideos: filteredVideos,
        }}
      >
        <VideoWrapper
          ratio={index % 7 ? `56.25` : `100`}
          fadeOutImage={
            currentVideoPlayerId === content.node.id &&
            shouldImageFadeOut &&
            content.node.data.vimeo_mp4_file.url.length > 0
          }
        >
          <div className="content">
            <LazyLoad height={400} offset={200}>
              <ShortVideo
                video={content.node.data.vimeo_mp4_file.url}
                width={1920}
                height={1080}
                playerId={`all_directors_video_${index}`}
                embed_url={content.node.data.vimeo_video.embed_url}
                setImageFadeOut={shouldImageFadeOut =>
                  setImageFadeOut(shouldImageFadeOut)
                }
                preload={index <= 5 ? "metadata" : "none"}
                isPlaying={
                  currentVideoPlayerId === content.node.id ? true : false
                }
                loadedSeconds={0.5}
                setOnReady={setOnReady}
              />
            </LazyLoad>

            <VimeoThumbnail
              width={1920}
              height={1080}
              prismicThumbnail={content.node.data.thumbnail.fluid}
              embed_url={content.node.data.vimeo_video.embed_url}
            />
          </div>
        </VideoWrapper>
        <Caption>
          {content.node.data.brand.text !== "" ? (
            <h2>{content.node.data.brand.text}</h2>
          ) : (
            <h2>{content.node.data.title.text}</h2>
          )}
          {content.node.data.director.uid !== null && (
            <p>{content.node.data.director.document.data.title.text}</p>
          )}
        </Caption>
      </Link>
    </VideoContainer>
  ));

  return <Container>{videos}</Container>;
};
