import React, { useEffect, useContext } from "react";
import styled from "styled-components";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";
import Media from "react-media";

// Previews
import { withPreview } from "gatsby-source-prismic";

// Context
import { PageTypeConsumer } from "../components/context/page-type";
import WindowWidthContext from "../components/context/window-width";

// Components
import { FilteredProduction } from "../components/filters/filtered-production";
import { DesktopFilteredProductionVideos } from "../components/filters/desktop-filtered-production-videos";
import { MobileFilteredProductionVideos } from "../components/filters/mobile-filtered-production-videos";

const Page = styled.div``;

const FilteredVideosContent = ({
  data,
  setPageType,
  location,
  pageContext,
}) => {
  const windowWidth = useContext(WindowWidthContext);

  useEffect(() => {
    setPageType("directors");
  }, []);

  return (
    <>
      <Helmet
        title={`${pageContext.tag} – Park Village`}
        meta={[
          {
            name: "og:title",
            content: `${pageContext.tag} – Park Village`,
          },
          {
            name: "twitter:title",
            content: `${pageContext.tag} – Park Village`,
          },
        ]}
      />

      <Page>
        <Media
          queries={{ medium: "(min-width: 769px)" }}
          defaultMatches={{ medium: windowWidth === 769 }}
          render={() => (
            <DesktopFilteredProductionVideos
              allVideos={data.allPrismicVideo.edges}
              videoLocation={`filtered`}
              location={location}
              activeFilter={pageContext.tag}
            />
          )}
        />
        <Media
          queries={{ medium: "(max-width: 768px)" }}
          defaultMatches={{ medium: windowWidth === 768 }}
          render={() => (
            <MobileFilteredProductionVideos
              allVideos={data.allPrismicVideo.edges}
              videoLocation={`filtered`}
              location={location}
              activeFilter={pageContext.tag}
            />
          )}
        />
        <FilteredProduction />
      </Page>
    </>
  );
};

const FilteredVideos = ({ data, location, pageContext }) => {
  return (
    <PageTypeConsumer>
      {({ setPageType }) => (
        <FilteredVideosContent
          setPageType={setPageType}
          data={data}
          location={location}
          pageContext={pageContext}
        />
      )}
    </PageTypeConsumer>
  );
};

export default withPreview(FilteredVideos);

export const query = graphql`
  query FilteredVideos($tag: String!) {
    allPrismicVideo(
      sort: { fields: last_publication_date, order: DESC }
      filter: { tags: { eq: $tag } }
    ) {
      edges {
        node {
          id
          uid
          tags
          data {
            title {
              text
            }
            brand {
              text
            }
            vimeo_video {
              title
              description
              embed_url
              width
              height
            }
            vimeo_mp4_file {
              url
            }
            director {
              uid
              document {
                ... on PrismicDirector {
                  id
                  data {
                    title {
                      text
                    }
                  }
                }
              }
            }
            thumbnail {
              alt
              url
              fluid(imgixParams: { w: 2000 }) {
                srcWebp
                srcSetWebp
              }
            }
          }
        }
      }
    }
  }
`;
